<template>
  <div>
    <form-data-provider-connect
      v-bind="dataProviderConfig"
      @custom-form:500-error="handle500"
      @custom-form:404-error="handle404"
      @custom-form:429-error="handle429"
      @custom-form:422-error="handle422"
    />
  </div>
</template>

<script>
import FormRenderer from '@/components/form/FormRenderer';
import FormPageMixin from '@/mixins/form-page-mixin';

export default {
  components: {
    FormDataProviderConnect: FormRenderer,
  },

  mixins: [FormPageMixin],

  props: {},

  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
