<template>
  <div
    ref="FileUploadUnexpectedErrorModal"
    class="modal"
    :class="{ 'is-active': isActive}"
  >
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Attention!
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="closeFileUploadUnexpectedErrorModal"
        />
      </header>
      <section class="modal-card-body">
        <article class="message is-warning">
          <div class="message-body">
            <p
              class="has-text-centered"
              style="padding: 10px;"
            >
              Your request was processed with success!
            </p>
            <hr style="margin: 5px 0 5px 0px">
            <p
              class="has-text-left"
              style="padding: 10px"
            >
              However at least one of your uploaded files had an issue and was not included in the
              request.
            </p>
            <p
              class="has-text-left"
              style="padding: 10px"
            >
              If you wish, you can review the files and submit again.
            </p>
          </div>
        </article>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          @click="closeFileUploadUnexpectedErrorModal"
        >
          Ok
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isActive: Boolean,
  },
  mounted() {
    this.registerClosingModalByPressingEscapeKeyEvent();
  },
  methods: {
    registerClosingModalByPressingEscapeKeyEvent() {
      document.addEventListener('keydown', (originalEvent) => {
        const event = originalEvent || window.event;

        if (event.keyCode === 27) { // Escape key
          this.closeFileUploadUnexpectedErrorModal();
        }
      });
    },
    closeFileUploadUnexpectedErrorModal() {
      this.isActive = false;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
