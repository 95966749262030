<template>
  <div class="ErrorPage ">
    <div class="ErrorPage-Content">
      <h1 class="title has-text-grey ">
        422
      </h1>
      <p class="subtitle has-text-grey-light ">
        {{ validationMessage }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    originalValidationErrorMessage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {};
  },
  computed: {
    /**
     * The backend can send certain validation messages which we want to use as it is.
     * Otherwise, the default message is used.
     * @returns {string}
     */
    validationMessage() {
      const allowedValidationMessages = [
        'Sorry, but registrations have stopped being collected.',
        'Sorry, but all registrations slots have been filled.',
      ];
      if (allowedValidationMessages.includes(this.originalValidationErrorMessage)) {
        return this.originalValidationErrorMessage;
      }

      return 'Sorry, there was an issue while processing your request. Please try again by refreshing the page.';
    },
  },
};
</script>
