export default {
  computed: {
    dataProviderConfig() {
      const {
        background, font, page, reloadInterval, title, urlHash, previewToken,
      } = this.$route.query;

      const { formId, companyId } = this.$route.params;

      // console.log(reloadInterval, typeof reloadInterval);

      return {
        formId,
        companyId,
        urlTemplate: `${process.env.VUE_APP_LAMBDA_BASE_URL}`,
        redirectHash: urlHash === undefined ? null : urlHash,
        showFormTitle: title === '1',
        redirectToFormAfterSubmit: reloadInterval !== undefined && reloadInterval !== '0',
        themePageBackgroundColor: page,
        themeFormBackgroundColour: background,
        themeFontColor: font,
        previewToken: previewToken === undefined ? null : previewToken,
      };
    },
  },

  methods: {
    handle404() {
      this.$emit('custom-form:404-error');
    },
    handle500() {
      this.$emit('custom-form:500-error');
    },
    handle429() {
      this.$emit('custom-form:429-error');
    },
    handle422(originalValidationErrorMessage) {
      this.$emit('custom-form:422-error', originalValidationErrorMessage);
    },
  },
};
