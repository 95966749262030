import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_KEY,
  appVersion: process.env.VUE_APP_BUGSNAG_VERSION,
  releaseStage: process.env.VUE_APP_BUGSNAG_RELEASE_STAGE,
  enabledReleaseStages: ['production', 'local', 'qa', 'qa2'],
  plugins: [new BugsnagPluginVue()],
});

const bugsnagVue = Bugsnag.getPlugin('vue');

export default bugsnagVue.installVueErrorHandler;
