import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundPage from '../views/errors/404';
import ErrorPage from '../views/errors/500';
import Form from '../views/Form';
import MaintenanceModePage from '../views/errors/503';

Vue.use(VueRouter);

const routes = [
  {
    path: '/form/:formId/:companyId',
    name: 'Form',
    component: Form,
  },
  {
    path: '/500',
    name: 'Error500',
    component: ErrorPage,
  },
  {
    path: '/404',
    name: 'Error404',
    component: NotFoundPage,
  },
  {
    path: '/503',
    name: 'Error503',
    component: MaintenanceModePage,
  },
  {
    path: '*',
    name: '404CatchAll',
    component: NotFoundPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
  router.beforeEach((to, from, next) => {
    if (to.name === 'Error503') {
      return next();
    }

    return next({ name: 'Error503' });
  });
}

if (process.env.VUE_APP_DEBUG_ROUTES === 'true') {
  router.addRoutes([
    {
      path: '/',
      redirect: {
        name: 'DebugHome',
      },
    },
    {
      path: '/debug',
      name: 'DebugHome',
      component: () => import(/* webpackChunkName: "debug" */ '@/views/debug/DebugHome'),
    },
    {
      path: '/form/:formId/:companyId/debug',
      name: 'DebugForm',
      component: () => import(/* webpackChunkName: "debug" */ '@/views/debug/DebugForm'),
    },
  ]);
}

export default router;
