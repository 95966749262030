<template>
  <div
    class="RMPE-FormContainer"
    :class="[{ 'isInIframe': isInIframe, }, formType, {'RMPE-PreviewMode':isOnPreviewMode() } ]"
  >
    <div
      v-if="isOnPreviewMode()"
      class="RMPE-PreviewMode-Copy"
    >
      PREVIEW MODE
    </div>
    <div
      v-if="(!isFormOpen || !isFormCollectingResponses || isMaxCapacityReached ) & !formLoading"
      class="RMPE-FormWrapper content"
    >
      <div
        :class="{ 'RMPE-CloseMessage': isDynamicWebForm }"
        v-html="closedMessage"
      />
    </div>
    <div
      v-else-if="showForm && !formSubmitted"
      class="RMPE-FormContent"
    >
      <div class="has-text-centered">
        <img
          v-if="bannerImage !== null"
          :src="bannerImage"
          :alt="formConfig.title"
        >
      </div>
      <div class="RMPE-FormWrapper">
        <h1
          v-if="formConfig.title !== null"
          class="has-text-weight-bold is-size-4 m-b-lg has-text-centered RMPE-FormTitle"
        >
          {{ formConfig.title }}
        </h1>
        <component
          :is="formType"
          :questions="formQuestions"
          :config="formConfig"
          :is-submitting="formIsSubmitting"
          :auto-focus-first-field="!isInIframe"
          :debug-mode="debugMode"
          @custom-form:submit-payload-changed="(payload) => $emit('custom-form:submit-payload-changed', payload)"
          @custom-form:submit="handleSubmit"
          @custom-form:validation-errors="handleValidationErrors"
        />
      </div>
    </div>

    <div
      v-else-if="formLoading"
      class="RMPE-FormWrapper"
    >
      <div class="RMPE-LoadingSpinner">
        <button class="button is-loading is-large" />
      </div>
    </div>
    <div
      v-else-if="formSubmitted"
      class="p-lg content ThankYou"
    >
      <div v-html="thanksMessage" />
    </div>
    <file-upload-unexpected-error-modal :is-active="showFileUploadErrorModal" />
  </div>
</template>
<script>
import 'iframe-resizer/js/iframeResizer.contentWindow.min';
import Bugsnag from '@bugsnag/js';
import StaticForm from '@rmpenterprise/microsite-custom-forms/src/components/form-types/static';
import DynamicForm from '@rmpenterprise/microsite-custom-forms/src/components/form-types/dynamic';
import WebFontLoader from 'webfontloader';

import {
  isAfter,
  isSameDay,
  parseISO,
} from 'date-fns';

import get from 'lodash/get';
import FileUploadUnexpectedErrorModal from '../modals/FileUploadUnexpectedErrorModal';

export default {

  components: {
    StaticForm,
    DynamicForm,
    FileUploadUnexpectedErrorModal,
  },

  props: {
    formId: {
      type: [String, Number],
      default: null,
      required: true,
    },
    companyId: {
      type: [String, Number],
      default: null,
      required: true,
    },
    urlTemplate: {
      type: String,
      default: null,
      required: true,
    },
    redirectUrl: {
      type: String,
      default: null,
    },
    redirectHash: {
      type: String,
      default: null,
    },
    previewToken: {
      type: String,
      default: null,
    },
    showFormTitle: {
      type: Boolean,
      default: false,
    },
    redirectToFormAfterSubmit: {
      type: Boolean,
      default: false,
    },
    themePageBackgroundColor: {
      type: String,
      default: null,
    },
    themeFormBackgroundColour: {
      type: String,
      default: null,
    },
    themeFontColor: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      showForm: false,
      showValidationError: false,
      formLoading: false,
      formSubmitted: false,
      formDefinition: null,
      trustedRedirectUrl: null,
      formIsSubmitting: false,
      parentFrameClient: null,
      showFileUploadErrorModal: false,
    };
  },

  computed: {
    formConfig() {
      if (this.formDefinition === null) return {};

      const formParts = Object.values(this.formDefinition.appSchema.components);
      const formAttributes = formParts.find((part) => part.type === 'Form').attrs;
      const questionsFlow = formParts.find((part) => part.type === 'Form').attrs.transitions;

      return {
        registerButton: formAttributes.registerButton,
        title: this.showFormTitle ? this.formDefinition.name : null,
        optInStatement: formAttributes.otherData && formAttributes.otherData.popup ? formAttributes.otherData.popup : null,
        questionsFlow,
      };
    },
    isDynamicWebForm() {
      return this.formType === 'DynamicForm';
    },
    formType() {
      if (this.formDefinition === null) return null;

      return this.formDefinition.experienceType === 'dynamic' ? 'DynamicForm' : 'StaticForm';
    },
    formQuestions() {
      return this.pluckFormAttributes('Form', 'questions');
    },
    thanksMessage() {
      const defaultMessage = '<p>Thanks for your response</p>';
      return this.pluckFormAttributes('Thanks', 'message') || defaultMessage;
    },
    closedMessage() {
      const defaultMessage = '<p>This form is now closed</p>';
      return this.pluckFormAttributes('Thanks', 'closed_message') || defaultMessage;
    },
    bannerImage() {
      if (this.formDefinition === null) return null;

      return this.formDefinition.bannerImage;
    },
    isFormOpen() {
      // Only a user with access to the form in Connect knows the preview token
      if (this.isOnPreviewMode()) {
        return true;
      }

      if (this.formDefinition === null || this.formDefinition.webFormAccess === false) return false;

      // Get todays date in UTC
      const todayLocalTime = parseISO(new Date().toISOString());

      const formsEndDate = parseISO(this.formDefinition.endDate);

      return isAfter(formsEndDate, todayLocalTime) || isSameDay(todayLocalTime, formsEndDate);
    },
    isMaxCapacityReached() {
      return this.formDefinition.isCapacityReached;
    },
    isFormCollectingResponses() {
      // Only a user with access to the form in Connect knows the preview token
      if (this.isOnPreviewMode()) {
        return true;
      }

      if (this.formDefinition === null) {
        return false;
      }

      if (this.formDefinition.stopCollectingResponsesAt === undefined || this.formDefinition.stopCollectingResponsesAt === null) {
        return true;
      }

      const todayLocalTime = parseISO(new Date().toISOString());

      const stopCollectingResponsesAt = parseISO(this.formDefinition.stopCollectingResponsesAt);

      return isAfter(stopCollectingResponsesAt, todayLocalTime);
    },

    isInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    debugMode() {
      return process.env.VUE_APP_DEBUG_ROUTES === 'true';
    },
  },
  mounted() {
    this.getFormDefinition();
    this.setupIframe();
  },
  updated() {
    this.handlePressToEnterHintOnFirstScreen();
  },

  methods: {
    isOnPreviewMode() {
      return this.formDefinition !== null && this.formDefinition.previewToken === this.previewToken;
    },
    getFormDefinition() {
      if (this.formDefinition !== null) return;

      this.showForm = false;
      this.formLoading = true;

      this.$axios.get(`${this.urlTemplate}/${this.formId}/${this.companyId}`)
        .then((response) => {
          this.formDefinition = this.reformatFormDefinition(response);

          this.setQueryParamStylingAttributes(this.formDefinition.theme);

          this.setPageTitle(this.formDefinition.name);

          this.$emit('custom-form:loaded-form-definition', this.formDefinition);

          setTimeout(() => {
            this.showForm = true;
            this.formLoading = false;
          }, 200);
        })
        .catch((error) => {
          this.formLoading = false;

          this.handleErrorResponses(error);
        }).finally(() => {
          this.resolveMarginWhenInFrame();
        });
    },
    /**
     * Dynamic forms that are placed in third party websites through an iframe won't have the first screen
     * "Enter" button focus so the "press enter" to start hint is redundant.
     *
     * @see https://rmpenterprise.atlassian.net/browse/CONNECT-4539
     */
    handlePressToEnterHintOnFirstScreen() {
      if (!this.isDynamicWebForm || !this.isInIframe) {
        return;
      }

      const firstQuestionHint = document.getElementsByClassName('RMPE-CustomSubmit-Hint');

      if (firstQuestionHint.length === 0) {
        return;
      }

      firstQuestionHint[0].style.display = 'none';
    },
    /**
     * While static forms have all their questions in the html, dynamic forms don't.
     * Each question is loaded into the dom and as the user answers the payload is generated.
     * This means that hidden questions need to be injected into the payload.
     * @param payload
     * @returns {*}
     */
    addHiddenQuestionValuesWhenTheFormIsDynamic(payload) {
      if (!this.isDynamicWebForm) {
        return payload;
      }

      this.formQuestions.filter(question => question.hidden && question.initialVal !== null)
        .forEach((question) => {
          payload[question.id] = question.initialVal;
        });

      return payload;
    },
    /**
     * Given that the file uploads are handled directly S3, we still need to send to the lambda some meta information about each file.
     * Here is what we are doing:
     *  - Loop through the form answers and check if the answer is related to a file upload question
     *    - if so, then we collect a bit of information like file mime type, size, etc
     * This information will allow Connect to provide some details about the uploaded file even when it is pending a scan.
     *
     * @param payload
     * @returns {{}}
     */
    addMetaDataOfFileUploadQuestionsToFormPayload(payload) {
      const fileUploadQuestionsIds = this.formQuestions.filter(question => question.type === 'file_upload').map(question => question.id);
      const fileUploadMetaData = Object.keys(payload)
        .filter(questionId => {
          return fileUploadQuestionsIds.includes(questionId);
        })
        .reduce((map, questionId) => {
          const filenameSplitByDot = payload[questionId].name.split('.');
          const filenameWithExtension = filenameSplitByDot.pop();
          const filenameWithoutExtension = filenameSplitByDot.join();
          map[questionId] = {
            originalFilename: filenameWithoutExtension,
            fileExtension: `.${filenameWithExtension}`,
            fileMimeType: payload[questionId].type,
            fileSizeInBytes: payload[questionId].size,
          };
          return map;
        }, {});
      return { fields: { ...payload, ...fileUploadMetaData } };
    },
    handleSubmit({ payload }) {
      if (this.formIsSubmitting) {
        return;
      }

      this.showFileUploadErrorModal = false;
      this.formIsSubmitting = true;
      this.showValidationError = false;
      this.$emit('custom-form:submit', payload);

      const payloadWithHiddenQuestionsData = this.addHiddenQuestionValuesWhenTheFormIsDynamic(payload);
      const payloadWithFileUploadMetaData = this.addMetaDataOfFileUploadQuestionsToFormPayload(payloadWithHiddenQuestionsData);

      const submitUrlParams = { params: { redirectHash: this.redirectHash } };

      if (this.isOnPreviewMode()) {
        submitUrlParams.params.previewToken = this.previewToken;
      }

      this.$axios.post(`${this.urlTemplate}/${this.formId}/${this.companyId}`, payloadWithFileUploadMetaData, submitUrlParams)
        .then(async (response) => {
          const responseUUID = response.data.data.response !== undefined ? response.data.data.response : null;
          const fileUploadsMetaData = response.data.data.files !== undefined ? response.data.data.files : {};

          if (Object.keys(fileUploadsMetaData).length > 0) {
            await this.uploadFilesViaPreSignedPost(payload, responseUUID, fileUploadsMetaData);
            if (this.showFileUploadErrorModal) {
              this.formIsSubmitting = false;
              this.formSubmitted = false;
              return;
            }
          }

          this.$emit('custom-form:submission-success', payloadWithFileUploadMetaData);

          this.setTrustedRedirect(response);

          // Redirect the whole page on success
          if (this.trustedRedirectUrl !== null && !this.isInIframe) {
            window.location.href = this.trustedRedirectUrl;
            return;
          }

          // Redirect the parent window
          if (this.trustedRedirectUrl !== null && this.isInIframe) {
            // console.log('TODO redirect via parent iframe');
            this.parentFrameClient.sendMessage({
              event: 'form:redirect-to',
              payload: {
                url: this.trustedRedirectUrl,
              },
            });
            return;
          }

          // Go back to form after a certain period of time
          if (this.redirectToFormAfterSubmit) {
            setTimeout(() => {
              this.formSubmitted = false;
              this.$emit('custom-form:reset', payload);

              if (this.isInIframe) {
                this.parentFrameClient.sendMessage({ event: 'form:reset' });
              }
            }, 5000);
          }

          setTimeout(() => {
            this.formIsSubmitting = false;
            this.formSubmitted = true;
          }, 200);
        })
        .catch((error) => {
          this.formIsSubmitting = false;
          this.handleErrorResponses(error);
        });
    },
    /**
     * @link https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-UsingHTTPPOST.html
     * @param originalFormPayload
     * @param responseUUID
     * @param fileUploadsMetaData
     */
    async uploadFilesViaPreSignedPost(originalFormPayload, responseUUID, fileUploadsMetaData) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [questionId, metaData] of Object.entries(fileUploadsMetaData)) {
        // eslint-disable-next-line no-await-in-loop
        const preSignedPostData = await this.requestToSignASingleFileUpload(responseUUID, questionId, metaData);

        // eslint-disable-next-line no-await-in-loop
        await this.directlyUploadFileToS3(originalFormPayload, preSignedPostData, questionId);
      }
    },
    /**
     *
     * @param responseUUID
     * @param questionId
     * @param metaData
     * @returns {Promise<null>}
     */
    async requestToSignASingleFileUpload(responseUUID, questionId, metaData) {
      let preSignedPostData = null;
      await this.$axios.post(`${this.urlTemplate}/pre-file-upload/${this.formId}/${this.companyId}/${responseUUID}/${questionId}/`, metaData)
        .then((response) => {
          // console.log('response of pre-post sign', response);
          preSignedPostData = response.data.data.settings;
          // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
          this.showFileUploadErrorModal = true;
          // this.handleErrorResponses(error);
        });

      return preSignedPostData;
    },
    /**
     *
     * @param originalFormPayload
     * @param preSignedPostData
     * @param questionId
     * @returns {Promise<void>}
     */
    async directlyUploadFileToS3(originalFormPayload, preSignedPostData, questionId) {
      const file = originalFormPayload[questionId];
      const formData = new FormData();

      Object.keys(preSignedPostData.fields).forEach(key => {
        formData.append(key, preSignedPostData.fields[key]);
      });

      formData.append('file', file);

      // eslint-disable-next-line no-unused-vars
      await this.$axios.post(preSignedPostData.url, formData).then((response) => {
        return true;
        // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        this.showFileUploadErrorModal = true;

        // this.handleErrorResponses(error);
      });
    },
    handleErrorResponses(httpResponse) {
      // console.log('Lambda responded with an error', httpResponse);
      Bugsnag.notify(httpResponse);

      const statusCode = get(httpResponse, 'response.status', null);
      const errorCodesWithCustomErrorPages = [422, 429, 404];
      const originalValidationErrorMessage = get(httpResponse, 'response.data.data.message', null);

      if (!errorCodesWithCustomErrorPages.includes(statusCode)) {
        // 500 Internal Server Error
        this.$emit('custom-form:500-error');
        return;
      }

      // 422 Unprocessable Entity
      if (statusCode === 422) {
        this.$emit('custom-form:422-error', originalValidationErrorMessage);
      }

      // 429 Too Many Requests
      if (statusCode === 429) {
        this.$emit('custom-form:429-error');
      }

      // 404 Not Found
      if (statusCode === 404) {
        this.$emit('custom-form:404-error');
      }
    },
    handleValidationErrors(payload) {
      this.$emit('custom-form:validation-errors', payload);
    },

    reformatFormDefinition(response) {
      return response.data.data.form;
    },

    setTrustedRedirect(response) {
      // NOTE leaving this as a method as we may add more logic here
      this.trustedRedirectUrl = response.data.data.redirectUrl;
    },
    updateTheme(themeData) {
      const { theme } = themeData;
      if (theme.legacy) {
        document.documentElement.style.setProperty('--form-font-color', `#${theme.themeFontColor}`);
        document.documentElement.style.setProperty('--form-background-color', `#${theme.themeFormBackgroundColour}`);
        document.documentElement.style.setProperty('--page-background-color', `#${theme.themePageBackgroundColor}`);
        return;
      }
      document.documentElement.style.setProperty('--form-background-color', theme.formBackground);
      document.documentElement.style.setProperty('--form-background-image', theme.formBackgroundImageUrLSrc ? `url("${theme.formBackgroundImageUrLSrc}")` : null);
      document.documentElement.style.setProperty('--form-button-background-color', theme.formButtonBackground);
      document.documentElement.style.setProperty('--form-button-border-color', theme.formButtonBorderColour ? theme.formButtonBorderColour : 'transparent');
      document.documentElement.style.setProperty('--form-button-text-color', theme.formButtonTextColour);
      document.documentElement.style.setProperty('--form-text-answer-color', theme.formAnswersTextColour);
      document.documentElement.style.setProperty('--form-text-question-color', theme.formQuestionsTextColour);

      if (theme.formFontName) {
        WebFontLoader.load({
          google: {
            families: [theme.formFontName],
          },
        });
        document.documentElement.style.setProperty('--form-font', theme.formFontName);
      }
    },

    setQueryParamStylingAttributes(theme) {
      // Check if theme parameters are available
      if (this.themeFontColor || this.themeFormBackgroundColour || this.themePageBackgroundColor) {
        this.updateTheme({
          theme: {
            legacy: true,
            themeFontColor: this.validateColor(this.themeFontColor) ? this.themeFontColor : '000',
            themeFormBackgroundColour: this.validateColor(this.themeFormBackgroundColour) ? this.themeFormBackgroundColour : 'fff',
            themePageBackgroundColor: this.validateColor(this.themePageBackgroundColor) ? this.themePageBackgroundColor : 'f7f7f7',
          },
        });
        return;
      }

      // This condition should never happen but just in case...
      if (theme === undefined) {
        this.updateTheme({
          theme: {
            legacy: true,
            themeFontColor: '000',
            themeFormBackgroundColour: 'fff',
            themePageBackgroundColor: 'f7f7f7',
          },
        });
        return;
      }

      // Default, wanted behaviour, is for the form to use the theme properties from the json schema
      this.updateTheme({ theme });
    },

    validateColor(color) {
      return typeof color === 'string' && color.length >= 3 && color.length <= 6;
    },

    setupIframe() {
      if (!this.isInIframe) return;

      window.iFrameResizer = {
        onReady: () => {
          this.parentFrameClient = window.parentIFrame;
          this.parentFrameClient.sendMessage({ event: 'form:loaded' });
        },
      };
    },
    resolveMarginWhenInFrame() {
      if (!this.isInIframe) return;

      if (!this.isDynamicWebForm) {
        document.body.classList.add('m-lg');
      }
    },
    pluckFormAttributes(formPartType, attributeKey) {
      if (this.formDefinition === null) return null;

      const formParts = Object.values(this.formDefinition.appSchema.components);
      const formPart = formParts.find((part) => part.type === formPartType);

      return formPart.attrs[attributeKey];
    },

    setPageTitle(pageTitle) {
      pageTitle = this.isOnPreviewMode() ? `PREVIEW MODE - ${pageTitle}` : pageTitle;
      document.title = pageTitle;
    },
  },
};
</script>

<style lang="scss" scoped>

.ThankYou {
  //display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden auto;

  & > div {
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  }
}

.RMPE-FormContainer {

  .RMPE-PreviewMode-Copy {
    text-align: center;
    background: black;
    color: white;
    padding: 15px;
    font-weight: bold;
  }

  &.StaticForm {
    margin: 0 auto;
    max-width: 700px;
    margin-top: 2rem;

    &.isInIframe {
      margin-top: 0;
    }

    .RMPE-FormWrapper {
      padding: 2rem;
    }
  }

  &.DynamicForm {
    position: relative;
    width: 100%;
    height: 100vh;
  }
}

.RMPE-CloseMessage {
  max-width: 700px;
  margin: 2rem auto 0;
  padding: 2em;
  border: 1px solid;
}

</style>
