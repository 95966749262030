/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

window.axios = axios;

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const axiosPlugin = {
  install(vue) {
    vue.prototype.$axios = axios; //eslint-disable-line
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  axiosPlugin,
};
