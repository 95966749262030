import Vue from 'vue';
import vuelidate from 'vuelidate';
import bugsnagErrorHandler from './bugsnag';
import App from './App';
import router from './router';
import store from './store';
import './scss/app.scss';
import { axiosPlugin } from './axios';

Vue.config.productionTip = false;

bugsnagErrorHandler(Vue);

Vue.use(vuelidate);
Vue.use(axiosPlugin);
Vue.component('font-awesome', () => import(/* webpackChunkName: "debug" */ './font-awesome'));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
