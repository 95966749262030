<template>
  <div class="ErrorPage ">
    <div class="ErrorPage-Content">
      <h1 class="title has-text-grey ">
        429
      </h1>
      <p class="subtitle has-text-grey-light ">
        Too many requests. Please try again later.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>
