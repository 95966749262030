<template>
  <div id="app">
    <router-view
      v-if="errorState === null"
      @custom-form:500-error="errorState = 500"
      @custom-form:404-error="errorState = 404"
      @custom-form:429-error="errorState = 429"
      @custom-form:422-error="process422"
    />
    <Error404 v-else-if="errorState === 404" />
    <Error500 v-else-if="errorState === 500" />
    <Error429 v-else-if="errorState === 429" />
    <Error422
      v-else-if="errorState === 422"
      :original-validation-error-message="originalValidationErrorMessage"
    />
  </div>
</template>
<script>
import Error404 from './views/errors/404';
import Error500 from './views/errors/500';
import Error429 from './views/errors/429';
import Error422 from './views/errors/422';

export default {
  components: {
    Error404,
    Error500,
    Error429,
    Error422,
  },

  data() {
    return {
      originalValidationErrorMessage: null,
      errorState: null,
    };
  },
  methods: {
    process422(originalValidationErrorMessage) {
      this.originalValidationErrorMessage = originalValidationErrorMessage;
      this.errorState = 422;
    },
  },
};
</script>
