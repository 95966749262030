<template>
  <div class="ErrorPage">
    <div class="ErrorPage-Content">
      <h1 class="title has-text-grey ">
        503
      </h1>
      <p class="subtitle has-text-grey-light ">
        Maintenance Mode
      </p>
      <p class="has-text-grey-light is-size-5">
        We will be back online shortly
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.subtitle {
  margin-bottom: 5px!important;
}
</style>
