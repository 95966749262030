<template>
  <div class="ErrorPage">
    <div class="ErrorPage-Content">
      <h1 class="title has-text-grey ">
        Ops!
      </h1>
      <p class="subtitle has-text-grey-light ">
        Sorry, something went wrong we couldn't process your request.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>
